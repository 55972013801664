<template>
  <v-card>
    <!-- HEADER TOOLBAR -->
    <v-toolbar class="page-primary-card-header">
      <v-list-item dark>
        <v-list-item-avatar color="primary lighten-2">
          <v-icon>{{ card_header_props.header.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline">{{
            card_header_props.header.headLine
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
            card_header_props.header.subTitle
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-btn
        @click="
          card_header_props.helper.page_helper =
            !card_header_props.helper.page_helper
        "
        icon
        dark
      >
        <v-icon>info</v-icon>
      </v-btn>

      <v-dialog v-model="card_header_props.helper.page_helper" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>{{
            this.$t("_common.How_can_I_use_this_form")
          }}</v-card-title>

          <v-card-text>{{
            this.$t("_class_assignments._info.content")
          }}</v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="card_header_props.helper.page_helper = false"
              >{{ this.$t("_common.OK") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <!-- HEADER TOOLBAR -->

      <v-row>
        <v-col cols="12" sm="12" md="3" class="my-2 px-1">
          <v-card>
            <v-sheet class="px-4 pt-2">
              <div>
                <vs-button
                  v-if="pageType == this.$enums.ASSIGNMENT_TYPES.Meetings"
                  icon-pack="feather"
                  icon="icon-plus"
                  class="ml-1"
                  @click="addNewMeeting()"
                  >{{ $t("_exam_management.Meeting") }}
                </vs-button>

                <v-text-field :value="pageTypeMapping[pageType]" disabled>
                </v-text-field>
              </div>
              <!--              <v-select-->
              <!--                :items="selectPageType"-->
              <!--                :label="this.$t('_class_assignments.Please_Select')"-->
              <!--                v-model="pageType"-->
              <!--                @change="selectedPageType"-->
              <!--                class="m-0"-->
              <!--              ></v-select>-->

              <v-text-field
                v-model="search"
                :label="this.$t('_class_assignments.Search_Directory')"
                flat
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
              ></v-text-field>
            </v-sheet>
            <v-card-text style="height: 620px; overflow: auto">
              <v-treeview
                :items="treeData"
                v-model="treeDataModel"
                :search="search"
                activatable
                rounded
                hoverable
                open-all
                item-text="Title"
                item-key="Id"
                multiple="false"
                selection-type="independent"
                item-children="Children"
                color="accent"
                style="min-width: fit-content"
                @update:active="onSelectedClass"
              >
                <template v-slot:label="{ item }">
                  {{ item.Title.split(">")[0] }}
                  <v-icon v-if="item.Title.split('>')[1]" size="14"
                    >mdi-chevron-right</v-icon
                  >
                  {{ item.Title.split(">")[1] }}
                </template>
              </v-treeview>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="my-2 px-1">
          <v-card>
            <v-card-title>{{
              this.$t("_class_assignments.Available_classes")
            }}</v-card-title>
            <v-text-field
              style="padding: 10px 20px 0px 20px"
              v-model="searchAllClasses"
              append-icon="search"
              :label="this.$t('_class_assignments.Search')"
              single-line
              hide-details
            ></v-text-field>
            <v-data-table
              :headers="headers"
              v-model="selectedClasses"
              :items="availableClasses"
              ref="sortableAll"
              item-key="Id"
              :search="searchAllClasses"
              show-select
              @input="showSelected"
              :no-results-text="this.$t('_common.No_Data')"
              :no-data-text="this.$t('_common.No_Data')"
              height="573"
            ></v-data-table>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="1" class="my-2 pl-4">
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-col>
                <v-btn text icon color="green" @click="addRight">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
              <v-col>
                <v-btn text icon color="red" @click="addLeft">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="my-2 px-1">
          <v-card>
            <v-card-title>{{
              this.$t("_class_assignments.Assignment_in_Class")
            }}</v-card-title>
            <v-text-field
              style="padding: 10px 20px 0px 20px"
              v-model="searchAssigned"
              append-icon="search"
              :label="this.$t('_class_assignments.Search')"
              single-line
              hide-details
            ></v-text-field>
            <v-data-table
              :headers="headers"
              v-model="selectedAssigned"
              :items="assignedClasses"
              ref="sortableAssigned"
              item-key="Id"
              :search="searchAssigned"
              :no-results-text="this.$t('_common.No_Data')"
              :no-data-text="this.$t('_common.No_Data')"
              show-select
              height="573"
            ></v-data-table>
          </v-card>
        </v-col>
      </v-row>
  </v-card>
</template>

<script>
require("../../assets/css/neo.css");

export default {
  name: "AssignClass",
  data() {
    return {
      pageVisible: false,
      pageType: "",
      treeData: [],
      treeDataModel: [],
      selectedTopic: [],
      selectedExam: [],
      selectedMeeting: [],
      selectedClasses: [],
      selectedAssigned: [],
      headers: [
        {
          text: this.$t("_class_assignments.Name"),
          align: "left",
          sortable: false,
          value: "Name",
        },
      ],
      assignedClasses: [],
      allClasses: [],
      availableClasses: [],
      searchAllClasses: "",
      searchAssigned: "",
      isSelectable: true,
      selectPageType: [
        this.$t("_user_assignments.Topics"),
        this.$t("_user_assignments.Exams"),
        this.$t("_user_assignments.Meetings"),
      ],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      search: null,

      card_header_props: {
        header: {
          headLine: this.$t("_class_assignments.Class_Assignments"),
          subTitle: this.$t("_class_assignments.Class_Management_Text"),
          icon: "mdi-account-check",
        },
        helper: {
          absolute: true,
          opacity: 0.8,
          overlay: false,
          page_helper: false,
        },
      },
      pageTypeMapping: {
        Topics: this.$t("_user_assignments.Topics"),
        Exams: this.$t("_user_assignments.Exams"),
        Meetings: this.$t("_user_assignments.Meetings"),
      },
    };
  },
  methods: {
    getTopicsForTeacher() {
      this.$goc.request.get(this.$enums.API.Topic, (response) => {
        let courses = response.Result.Topics;
        for (var i = 0; i < courses.length; i++) {
          let lessons = courses[i].Parent.Title;
          let modules = courses[i].Parent.Parent.Title;
          courses[i].Title =
            courses[i].Title + " (" + modules + ">" + lessons + ")";
          courses[i].Children = [];
          this.treeData.push(courses[i]);
        }
      });
    },
    getTopicsForAdmin() {
      this.$goc.request.get(this.$enums.API.Topic, (response) => {
        let modules = response.Result.Topics;
        for (var i = 0; i < modules.length; i++) {
          let lessons = modules[i].Children;
          for (var ii = 0; ii < lessons.length; ii++) {
            let courses = lessons[ii].Children;
            for (var iii = 0; iii < courses.length; iii++) {
              courses[iii].Title =
                courses[iii].Title +
                " (" +
                modules[i].Title +
                ">" +
                lessons[ii].Title +
                ")";
              courses[iii].Children = [];
              this.treeData.push(courses[iii]);
            }
          }
        }
      });
    },
    getExams() {
      this.$goc.request.get(this.$enums.API.QuestionSheet, (response) => {
        this.treeData = [];
        response.Result.Sheets.forEach((element) => {
          if (
            this.$moment(element.ExpirationDate).valueOf() >
            new Date().getTime()
          ) {
            this.treeData.push(element);
          }
        });
      });
    },
    getMeetings() {
      let body = {};
      this.$goc.request.post(this.$enums.API.ListMeeting, body, (response) => {
        this.treeData = [];
        response.Result.Meetings.forEach((element) => {
          if (
            this.$moment(element.StartDate)
              .add(element.Duration.replace(/\D/g, ""), "s")
              .valueOf() > new Date().getTime()
          ) {
            this.treeData.push(element);
          }
        });
      });
    },
    showSelected() {
      this.$goc.console.log(this.selectedClasses);
    },
    existsInAssigned(item) {
      for (var i = 0, len = this.assignedClasses.length; i < len; i++) {
        if (item.Id == this.assignedClasses[i].Id) return true;
        if (i == len) return false;
      }
    },
    loadClassesByType() {
      if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Topics) {
        return this.getClassesByTopic();
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Exams) {
        return this.getClassesByExam();
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Meetings) {
        return this.getClassesByMeeting();
      }
    },
    getClasses() {
      this.allClasses = [];
      this.availableClasses = [];
      if (
        this.selectedTopic != null ||
        this.selectedExam != null ||
        this.selectedMeeting != null
      ) {
        this.$goc.request.get(this.$enums.API.Class, (response) => {
          this.allClasses = response.Result.classes;
          this.loadClassesByType().then((assignedClasses) => {
            if (assignedClasses != []) {
              this.availableClasses = this.allClasses.filter(function (el) {
                return !assignedClasses.find((u) => u.Id === el.Id);
              });
            } else this.availableClasses = this.allClasses;
          });
        });
      }
    },
    addRight(item) {
      this.$goc.console.log("right");
      this.$goc.console.log(this.selectedClasses);
      if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Topics) {
        this.assignClassToTopic();
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Exams) {
        this.assignClassToExam();
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Meetings) {
        this.assignClassToMeeting();
      }
    },
    addLeft() {
      this.$goc.console.log("left");
      this.$goc.console.log(this.selectedAssigned);
      if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Topics) {
        this.removeClassFromTopic();
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Exams) {
        this.removeClassFromExam();
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Meetings) {
        this.removeClassFromMeeting();
      }
    },
    getClassesByTopic() {
      const _self = this;
      this.assignedClasses = [];
      let body = {
        id: this.selectedTopic[0],
      };
      return new Promise((resolve) => {
        this.$goc.request.post(this.$enums.API.GetTopicFromClass, body, {
          then: (response) => {
            if (response.Result !== undefined) {
              response.Result.classes.forEach((element) => {
                if (_self.allClasses[element.Id])
                  delete _self.allClasses[_self.allClasses.indexOf(element)];
              });
              this.assignedClasses = response.Result.classes;
            }
            resolve(this.assignedClasses);
          },
        });
      });
    },
    getClassesByExam() {
      const _self = this;
      this.assignedClasses = [];
      let body = {
        id: this.selectedExam[0],
      };
      return new Promise((resolve) => {
        this.$goc.request.post(this.$enums.API.GetClassByExam, body, {
          then: (response) => {
            if (response.Result !== undefined) {
              response.Result.classes.forEach((element) => {
                if (_self.allClasses[element.Id])
                  delete _self.allClasses[_self.allClasses.indexOf(element)];
              });
              this.assignedClasses = response.Result.classes;
            }
            resolve(this.assignedClasses);
          },
        });
      });
    },
    getClassesByMeeting() {
      const _self = this;
      this.assignedClasses = [];
      let body = {
        id: this.selectedMeeting[0],
      };
      return new Promise((resolve) => {
        this.$goc.request.post(this.$enums.API.GetMeetingFromClass, body, {
          then: (response) => {
            if (response.Result !== undefined) {
              response.Result.classes.forEach((element) => {
                if (_self.allClasses[element.Id])
                  delete _self.allClasses[_self.allClasses.indexOf(element)];
              });
              this.assignedClasses = response.Result.classes;
            }
            resolve(this.assignedClasses);
          },
        });
      });
    },
    addNewMeeting() {
      this.$goc.page.load("/admin/exam/management?addmeeting=2");
    },
    onSelectedClass(item) {
      if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Topics) {
        this.selectedTopic = item;
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Exams) {
        this.selectedExam = item;
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Meetings) {
        this.selectedMeeting = item;
      }

      if (item[0] == null) this.pageVisible = false;
      else {
        this.pageVisible = true;
      }
      if (item.length > 0) {
        this.getClasses();
      }
    },
    assignClassToTopic() {
      if (this.selectedClasses.length == 0) return;
      let classIdList = this.selectedClasses.map((e) => e.Id);
      var body;
      var confirm = this.$goc.notify.confirm(
        {
          title: this.$t("_common.SentMail"),
          message: this.$t("_exam_management.DoYouWantSendEmail"),
        },
        (isSentMail) => {
          if (isSentMail) {
            this.$goc.notify.success({
              title: this.$t("_notification.NotificationMail"),
              message: this.$t("_notification.NotificationSentSuccessfully"),
            });
          }
          body = {
            AssigneeList: classIdList,
            TargetId: this.selectedTopic[0],
            isSendMail: isSentMail,
          };

          this.$goc.request.post(this.$enums.API.AssignTopicToClass, body, {
            then: (res) => {
              this.successNotify(
                this.$t("_class_assignments.Successfully_added_class_to_topic")
              );
            },
            final: () => {
              this.getClasses();
            },
          });
        }
      );
    },
    assignClassToExam() {
      if (this.selectedClasses.length == 0) return;
      let classIdList = this.selectedClasses.map((e) => e.Id);
      var body;
      var confirm = this.$goc.notify.confirm(
        {
          title: this.$t("_common.SentMail"),
          message: this.$t("_exam_management.DoYouWantSendEmail"),
        },
        (isSentMail) => {
          if (isSentMail) {
            this.$goc.notify.success({
              title: this.$t("_notification.NotificationMail"),
              message: this.$t("_notification.NotificationSentSuccessfully"),
            });
          }
          body = {
            AssigneeList: classIdList,
            TargetId: this.selectedExam[0],
            isSendMail: isSentMail,
          };
          this.$goc.request.post(this.$enums.API.AssignExamToClass, body, {
            then: () => {
              this.successNotify(
                this.$t("_class_assignments.Successfully_added_class_to_exam")
              );
            },
            final: () => {
              this.getClasses();
            },
          });
        }
      );
    },
    assignClassToMeeting() {
      if (this.selectedClasses.length == 0) return;
      let classIdList = this.selectedClasses.map((e) => e.Id);
      var body;
      var confirm = this.$goc.notify.confirm(
        {
          title: this.$t("_common.SentMail"),
          message: this.$t("_exam_management.DoYouWantSendEmail"),
        },
        (isSentMail) => {
          if (isSentMail) {
            this.$goc.notify.success({
              title: this.$t("_notification.NotificationMail"),
              message: this.$t("_notification.NotificationSentSuccessfully"),
            });
          }
          body = {
            AssigneeList: classIdList,
            TargetId: this.selectedMeeting[0],
            isSendMail: isSentMail,
          };
          this.$goc.request.post(this.$enums.API.AssignMeetingToClass, body, {
            then: () => {
              this.successNotify(
                this.$t(
                  "_class_assignments.Successfully_added_class_to_meeting"
                )
              );
            },
            final: () => {
              this.getClasses();
            },
          });
        }
      );
    },

    removeClassFromTopic(item) {
      if (this.selectedAssigned.length == 0) return;
      let classIdList = this.selectedAssigned.map((e) => e.Id);
      var body;
      this.$goc.notify.confirm(
        {
          title: this.$t("_common.SentMail"),
          message: this.$t("_exam_management.DoYouWantSendEmail"),
        },
        (isSentMail) => {
          if (isSentMail) {
            this.$goc.notify.success({
              title: this.$t("_notification.NotificationMail"),
              message: this.$t("_notification.NotificationSentSuccessfully"),
            });
          }
          body = {
            AssigneeList: classIdList,
            TargetId: this.selectedTopic[0],
            isSendMail: isSentMail,
          };
          this.$goc.request.post(this.$enums.API.RemoveTopicFromClass, body, {
            then: () => {
              this.successNotify(
                this.$t(
                  "_class_assignments.Successfully_removed_class_from_topic"
                )
              );
            },
            final: () => {
              this.getClasses();
            },
          });
        }
      );
    },
    removeClassFromExam(item) {
      if (this.selectedAssigned.length == 0) return;
      let classIdList = this.selectedAssigned.map((e) => e.Id);
      var body;
      this.$goc.notify.confirm(
        {
          title: this.$t("_common.SentMail"),
          message: this.$t("_exam_management.DoYouWantSendEmail"),
        },
        (isSentMail) => {
          if (isSentMail) {
            this.$goc.notify.success({
              title: this.$t("_notification.NotificationMail"),
              message: this.$t("_notification.NotificationSentSuccessfully"),
            });
          }
          body = {
            AssigneeList: classIdList,
            TargetId: this.selectedExam[0],
            isSendMail: isSentMail,
          };

          this.$goc.request.post(this.$enums.API.RemoveExamFromClass, body, {
            then: () => {
              this.successNotify(
                this.$t(
                  "_class_assignments.Successfully_removed_class_from_exam"
                )
              );
              this.selectedAssigned = [];
            },
            final: () => {
              this.getClasses();
            },
          });
        }
      );
    },
    removeClassFromMeeting(item) {
      if (this.selectedAssigned.length == 0) return;
      let classIdList = this.selectedAssigned.map((e) => e.Id);
      var body;
      this.$goc.notify.confirm(
        {
          title: this.$t("_common.SentMail"),
          message: this.$t("_exam_management.DoYouWantSendEmail"),
        },
        (isSentMail) => {
          if (isSentMail) {
            this.$goc.notify.success({
              title: this.$t("_notification.NotificationMail"),
              message: this.$t("_notification.NotificationSentSuccessfully"),
            });
          }
          body = {
            AssigneeList: classIdList,
            TargetId: this.selectedMeeting[0],
            isSendMail: isSentMail,
          };
          this.$goc.request.post(this.$enums.API.RemoveMeetingFromClass, body, {
            then: () => {
              this.successNotify(
                this.$t(
                  "_class_assignments.Successfully_removed_class_from_meeting"
                )
              );
              this.selectedAssigned = [];
            },
            final: () => {
              this.getClasses();
            },
          });
        }
      );
    },
    selectedPageType(item) {
      this.pageType = this.$route.params.type;
      let role = JSON.parse(localStorage.getItem("userInfo")).userRole;

      if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Topics) {
        if (role == "LmsTeacher") {
          this.getTopicsForTeacher();
        } else if (role == "LmsAdministrator") {
          this.getTopicsForAdmin();
        }
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Exams) {
        this.getExams();
      } else if (this.pageType == this.$enums.ASSIGNMENT_TYPES.Meetings) {
        this.getMeetings();
      }
    },
    errorNotify(message) {
      this.$vs.notify({
        title: this.$t("_common.ThereWasProblem"),
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "danger",
      });
    },
    warningNotify(message) {
      this.$vs.notify({
        title: this.$t("_common.Warning"),
        text: message,
        iconPack: "feather",
        icon: "icon-warning-circle",
        color: "warning",
      });
    },
    successNotify(message) {
      this.$vs.notify({
        title: this.$t("_common.Success"),
        text: message,
        iconPack: "feather",
        icon: "icon-success-circle",
        color: "success",
      });
    },
  },
  mounted() {
    this.$goc.setModule("AssignTopic", this);
    this.selectedPageType();
  },
  watch: {
    "$route.params.type"() {
      this.treeData = [];
      this.selectedPageType();
    },
  },
  computed: {
    indexedItems() {
      return this.allClasses.map((item, index) => ({
        index: index,
        ...item,
      }));
    },
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
  },
};
</script>

<style scoped>
.scroll-area-topics {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
}
</style>
